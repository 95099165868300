import React, { Component } from 'react';
import PageBodyContainer from "../shared/components/PageBodyContainer";
import {Link} from "react-router-dom";
import {LipseysApiUrl} from "../shared/Constants";

const url = LipseysApiUrl + "Integration/Order";
class Orders extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <PageBodyContainer>
                <h1>Order</h1>
                <h3>api/Integration/Order</h3>
                <ul>
                    <li><a href="#APIOrder"><strong>API Order</strong></a></li>
                    <li><a href="#DropShipAccessories"><strong>Drop Ship Accessories - Direct To Consumer</strong></a></li>
                    <li><a href="#DropShipfirearms"><strong>Drop Ship Firearms</strong></a></li>
                </ul>

                <hr/>

                {/* API Order */}
                <div className="row" id="APIOrder">
                    <div className="col-md-6">
                        <h3>API Order</h3>
                        <h4>Post - api/Integration/Order/APIOrder</h4>
                        <p>
                            Creates an order from a list of items. If an open order exists of the same type (long gun / handgun) it will add to that order,
                            otherwise a new order is created. The orders are based on the current user by <Link to={"auth"}>Token</Link>.
                        </p>
                        <p>The shipping location for the order determined by the user credentials used to obtain the Token. If you are unsure what location your email is associated with, please contact your Lipsey's Sales Executive.</p>
                        <p>Session Token should be passed as a header as Token.<br/>Ex: Token: nMFW97zPsmmEU64VVSjg8aaO7PhkutYWqvOnAPC9PAE=</p>
                        <p>To obtain a session token see <Link to={"auth"}><strong>Authentication</strong></Link></p>
                        <div>
                            <strong>Body Parameters:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"PONumber": String,<br/>
                                &emsp;&emsp;"DisableEmail": Bool,<br/>
                                &emsp;&emsp;"Items": [ <strong className="text-danger">(Required, Minimum 1)</strong><br/>
                                &emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"ItemNo": String, <strong className="text-danger">(Required [UPC, Lipseys Item Number, Mfg Model #])</strong><br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"Quantity": Int, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"Note": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;]<br/>
                                {'}'}<br/>
                                <strong className="text-danger">[Item Number can be Lipsey's Item #, MFG Model #, or UPC]</strong>
                            </pre></code>
                        </div>
                        <div>
                            <strong>Response example:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;"data": [<br/>
                                &emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"itemNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"lipseysItemNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"note": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"requestedQuantity": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"fulfilledQuantity": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"exists": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"blocked": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"allocated": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"validForCart": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"validForShipTo": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"orderError": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"orderNumber": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"price": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"totalPrice": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;]<br/>
                                {'}'}
                            </pre></code>
                        </div>

                    </div>

                    <div className="col-md-6">
                        <div>
                            <h3>Node (Request)</h3>
                            <code><pre>
                                var request = require("request");<br/>
                                var options = {'{'}<br/>
                                &emsp;&emsp;method: 'POST',<br/>
                                &emsp;&emsp;url: '{url}/APIOrder',<br/>
                                &emsp;&emsp;headers: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Token: '<Link to={"auth"}>Token</Link>',<br/>
                                &emsp;&emsp;&emsp;&emsp;'Content-Type': 'application/json'<br/>
                                &emsp;&emsp;{'}'},<br/>
                                &emsp;&emsp;body: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;PONumber: '[PONumber]',<br/>
                                &emsp;&emsp;&emsp;&emsp;DisableEmail: [DisableEmail],<br/>
                                &emsp;&emsp;&emsp;&emsp;Items: [<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;ItemNo: '[ItemNo]',<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Quantity: [Quantity],<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Note: '[Note]',<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;&emsp;&emsp;]<br/>
                                &emsp;&emsp;{'}'},<br/>
                                &emsp;&emsp;json: true<br/>
                                {'}'};<br/><br/>
                                request(options, function (error, response, body) {'{'}<br/>
                                &emsp;&emsp;if (error) throw new Error(error);<br/>
                                &emsp;&emsp;console.log(body);<br/>
                                {'}'});
                            </pre></code>
                        </div>
                    </div>
                </div>
                <hr/>

                {/* DropShip */}
                <div className="row" id="DropShipAccessories">
                    <div className="col-md-6">
                        <h3>Drop Ship</h3>
                        <h4>Post - api/Integration/Order/DropShip</h4>
                        <h5>
                            <a href={"https://www.lipseys.com/crm/lipseys%20accessories%20fulfillment%20program%20guidelines"} target="_blank" rel="noreferrer noopener">
                                DropShip Accessory Policy
                            </a>
                        </h5>
                        <p>Drop Ship - Creates a consumer direct Dropship order for accessories and optics.</p>
                        <p>Billing address fields should be completed with your consumer's billing address, which prints on the packing slip.</p>
                        <p><strong>A Dropship account is required to submit drop ship orders (separate from normal account).</strong></p>
                        <p><strong>Please <a href={"https://www.lipseys.com/contactus"} target="_blank" rel="noreferrer noopener">Contact Us</a> to create a Dropship account.</strong></p>
                        <p>Orders Over $500.00 will be signature required.</p>
                        <p>Firearms and NFA / Class 3 items cannot be ordered.</p>
                        <p>Session Token should be passed as a header as Token.<br/>Ex: Token: nMFW97zPsmmEU64VVSjg8aaO7PhkutYWqvOnAPC9PAE=</p>
                        <p>To obtain a session token see <Link to={"auth"}>Authentication</Link></p>
                        <div>
                            <strong>Body Parameters:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"Warehouse": String,<br/>
                                &emsp;&emsp;"PoNumber": String, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;"BillingName": String, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;"BillingAddressLine1": String, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;"BillingAddressLine2": String,<br/>
                                &emsp;&emsp;"BillingAddressCity": String, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;"BillingAddressState": String, <strong className="text-danger">(Required, 2 Letters)</strong><br/>
                                &emsp;&emsp;"BillingAddressZip": String, <strong className="text-danger">(Required, 5 Numbers)</strong><br/>
                                &emsp;&emsp;"ShippingName": String, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;"ShippingAddressLine1": String, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;"ShippingAddressLine2": String,<br/>
                                &emsp;&emsp;"ShippingAddressCity": String, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;"ShippingAddressState": String, <strong className="text-danger">(Required, 2 Letters)</strong><br/>
                                &emsp;&emsp;"ShippingAddressZip": String, <strong className="text-danger">(Required, 5 Numbers)</strong><br/>
                                &emsp;&emsp;"MessageForSalesExec": String,<br/>
                                &emsp;&emsp;"DisableEmail": Bool,<br/>
                                &emsp;&emsp;"Items": [ <strong className="text-danger">(Required, Minimum 1)</strong><br/>
                                &emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"ItemNo": String, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"Quantity": Int, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"Note": String<br/>
                                &emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;],<br/>
                                &emsp;&emsp;"Overnight": Bool,<br/>
                                {'}'}<br/>
                                <strong className="text-danger">[Item Number can be Lipsey's Item #, MFG Model #, or UPC]</strong>
                            </pre></code>
                        </div>
                        <div>
                            <strong>Response example:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;"data": {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;"lineItems": [<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"itemNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"lipseysItemNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"note": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"requestedQuantity": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"fulfilledQuantity": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"exists": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"blocked": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"allocated": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"validForCart": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"validForShipTo": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"orderError": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"orderNumber": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"price": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"totalPrice": Double<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;&emsp;&emsp;],<br/>
                                &emsp;&emsp;&emsp;&emsp;"orderNumber": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;"accountHold": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"lineItemChanges": [String],<br/>
                                &emsp;&emsp;&emsp;&emsp;"freight": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;"tax": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;"total": Double<br/>
                                &emsp;&emsp;{'}'}<br/>
                                {'}'}
                            </pre></code>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div>
                            <h3>Node (Request)</h3>
                            <code><pre>
                                var request = require("request");<br/><br/>
                                var options = {'{'}<br/>
                                &emsp;&emsp;method: 'POST',<br/>
                                &emsp;&emsp;url: '{url}/DropShip',<br/>
                                &emsp;&emsp;headers: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Token: '<Link to={"auth"}>Token</Link>',<br/>
                                &emsp;&emsp;&emsp;&emsp;'Content-Type': 'application/json'<br/>
                                &emsp;&emsp;{'}'},<br/>
                                &emsp;&emsp;body: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Warehouse: '[Warehouse]',<br/>
                                &emsp;&emsp;&emsp;&emsp;PoNumber: '[PoNumber]',<br/>
                                &emsp;&emsp;&emsp;&emsp;BillingName: '[BillingName]',<br/>
                                &emsp;&emsp;&emsp;&emsp;BillingAddressLine1: '[BillingAddressLine1]',<br/>
                                &emsp;&emsp;&emsp;&emsp;BillingAddressCity: '[BillingAddressCity]',<br/>
                                &emsp;&emsp;&emsp;&emsp;BillingAddressState: '[BillingAddressState]',<br/>
                                &emsp;&emsp;&emsp;&emsp;BillingAddressZip: '[BillingAddressZip]',<br/>
                                &emsp;&emsp;&emsp;&emsp;ShippingName: '[ShippingName]',<br/>
                                &emsp;&emsp;&emsp;&emsp;ShippingAddressLine1: '[ShippingAddressLine1]',<br/>
                                &emsp;&emsp;&emsp;&emsp;ShippingAddressCity: '[ShippingAddressCity]',<br/>
                                &emsp;&emsp;&emsp;&emsp;ShippingAddressState: '[ShippingAddressState]',<br/>
                                &emsp;&emsp;&emsp;&emsp;ShippingAddressZip: '[ShippingAddressZip]',<br/>
                                &emsp;&emsp;&emsp;&emsp;MessageForSalesExec: '[MessageForSalesExec]',<br/>
                                &emsp;&emsp;&emsp;&emsp;Overnight: [Overnight],<br/>
                                &emsp;&emsp;&emsp;&emsp;DisableEmail: [DisableEmail],<br/>
                                &emsp;&emsp;&emsp;&emsp;Items: [<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;ItemNo: '[ItemNo]',<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Quantity: [Quantity],<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Note: '[Note]'<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;&emsp;&emsp;]<br/>
                                &emsp;&emsp;{'}'},<br/>
                                &emsp;&emsp;json: true<br/>
                                {'}'};<br/><br/>
                                request(options, function (error, response, body) {'{'}<br/>
                                &emsp;&emsp;if (error) throw new Error(error);<br/>
                                &emsp;&emsp;console.log(body);<br/>
                                {'}'});
                            </pre></code>
                        </div>
                    </div>
                </div>
                <hr/>
                {/* DropShip firearms */}
                <div className="row" id="DropShipfirearms">
                    <div className="col-md-6">
                        <h3>Drop Ship Firearms</h3>
                        <h4>Post - api/Integration/Order/DropShipFirearm</h4>
                        <h5>
                            <a href={"https://www.lipseys.com/crm/lipseys%20firearms%20fulfillment%20program%20guidelines"} target="_blank" rel="noreferrer noopener">
                                DropShip Firearms Policy
                            </a>
                        </h5>
                        <p>Creates a firearms Dropship order that is shipped to a 3rd party firearms dealer based on FFL Number.</p>
                        <p><strong className="text-danger">Any orders submitted for FFL locations not on file must be validated before they ship.
                            A signed FFL copy must be submitted and verified. <span className="fs-120">Please Email validateffl@lipseys.com To Submit FFL.</span><br/><br/>
                            All California Orders Should <span className="fs-120">Email The California DOJ Number To validateffl@lipseys.com</span></strong>
                        </p>
                        <p><strong>A Lipsey's Dropship account is required to submit drop ship orders (separate from normal account).</strong></p>
                        <p><strong>Please <a href={"https://www.lipseys.com/contactus"} target="_blank" rel="noreferrer noopener">Contact Us</a> to create a Dropship account.</strong></p>
                        <p>Firearms Dropship orders can only contain firearms. Accessories should be shipped directly to your customer as a separate fulfillment order.</p>
                        <p>Session Token should be passed as a header as Token.<br/>Ex: Token: nMFW97zPsmmEU64VVSjg8aaO7PhkutYWqvOnAPC9PAE=</p>
                        <p>To obtain a session token see <Link to={"auth"}><strong>Authentication</strong></Link></p>
                        <div>
                            <strong>Body Parameters:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"Ffl": String, <strong className="text-danger">(Required, 15 Digits)</strong><br/>
                                &emsp;&emsp;"Po": String, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;"Name": String, <strong className="text-danger">(Required, Your Customer's Name)</strong><br/>
                                &emsp;&emsp;"Phone": String, <strong className="text-danger">(Required, Your Customer's Phone Number)</strong><br/>
                                &emsp;&emsp;"DelayShipping": Bool, <strong className="text-danger">(If True Order Ship Date Set To One Week From Time Of Order)</strong><br/>
                                &emsp;&emsp;"DisableEmail": Bool,<br/>
                                &emsp;&emsp;"Items": [ <strong className="text-danger">(Required, Minimum 1)</strong><br/>
                                &emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"ItemNo": String, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"Quantity": Int, <strong className="text-danger">(Required)</strong><br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"Note": String<br/>
                                &emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;]<br/>
                                {'}'}<br/>
                                <strong className="text-danger">[Item Number can be Lipsey's Item #, MFG Model #, or UPC]</strong>
                            </pre></code>
                        </div>
                        <div>
                            <strong>Response example:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;"data": {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;"lineItems": [<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"itemNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"lipseysItemNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"note": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"requestedQuantity": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"fulfilledQuantity": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"exists": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"blocked": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"allocated": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"validForCart": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"validForShipTo": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"orderError": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"orderNumber": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"price": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"totalPrice": Double<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;&emsp;&emsp;],<br/>
                                &emsp;&emsp;&emsp;&emsp;"orderNumber": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;"accountHold": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"lineItemChanges": [String],<br/>
                                &emsp;&emsp;&emsp;&emsp;"freight": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;"tax": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;"total": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;"misc": Double<br/>
                                &emsp;&emsp;{'}'}<br/>
                                {'}'}
                            </pre></code>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div>
                            <h3>Node (Request)</h3>
                            <code><pre>
                                var request = require("request");<br/><br/>
                                var options = {'{'}<br/>
                                &emsp;&emsp;method: 'POST',<br/>
                                &emsp;&emsp;url: '{url}/DropShipFirearm',<br/>
                                &emsp;&emsp;headers: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Token: '<Link to={"auth"}>Token</Link>',<br/>
                                &emsp;&emsp;&emsp;&emsp;'Content-Type': 'application/json'<br/>
                                &emsp;&emsp;{'}'},<br/>
                                &emsp;&emsp;body: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Ffl: '[15 Digit FFL #]',<br/>
                                &emsp;&emsp;&emsp;&emsp;Po: '[PoNumber]',<br/>
                                &emsp;&emsp;&emsp;&emsp;DelayShipping: [DelayShipping],<br/>
                                &emsp;&emsp;&emsp;&emsp;DisableEmail: [DisableEmail],<br/>
                                &emsp;&emsp;&emsp;&emsp;Name: '[Your Consumer's Name]',<br/>
                                &emsp;&emsp;&emsp;&emsp;Phone: '[Your Consumer's Phone Number]',<br/>
                                &emsp;&emsp;&emsp;&emsp;Items: [<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;ItemNo: '[ItemNo]',<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Quantity: [Quantity],<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Note: '[Note]'<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;&emsp;&emsp;]<br/>
                                &emsp;&emsp;{'}'},<br/>
                                &emsp;&emsp;json: true<br/>
                                {'}'};<br/><br/>
                                request(options, function (error, response, body) {'{'}<br/>
                                &emsp;&emsp;if (error) throw new Error(error);<br/>
                                &emsp;&emsp;console.log(body);<br/>
                                {'}'});
                            </pre></code>
                        </div>
                    </div>
                </div>
            </PageBodyContainer>
        )
    }
}

export default Orders;
