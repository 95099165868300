import React, { Component } from 'react';
import PageBodyContainer from "../shared/components/PageBodyContainer";
import {LipseysApiUrl} from "../shared/Constants";

const LoginUrl = LipseysApiUrl + 'Integration/Authentication/Login';
class Auth extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <PageBodyContainer>
                <h1>Authentication</h1>
                <h3>/api/Integration/Authentication/Login</h3>
                <br/>

                <div className="row">
                    <div className="col-md-6">
                        <h3>Login</h3>
                        <h4>Post - /api/Integration/Authentication/Login</h4>
                        <p>LOGIN - issues a token that to use for all requests. Includes some dealer information.</p>
                        <p>
                            To use the token each request should have a header titled Token<br/>
                            Ex: Token: nMFW97zPsmmEU64VVSjg8aaO7PhkutYWqvOnAPC9PAE=
                        </p>
                        <div>
                            <strong>Body Parameters:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"Email": String, <strong className="text-danger">(Required, Email Format)</strong><br/>
                                &emsp;&emsp;"Password": String <strong className="text-danger">(Required)</strong><br/>
                                {'}'}
                            </pre></code>
                        </div>
                        <div><strong>Response example:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"token": String,<br/>
                                &emsp;&emsp;"econtact": {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;&emsp;&emsp;"data": {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"name": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"email": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"cusNo": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"shipTo": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"locationName": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"slm": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;{'}'}<br/>
                                {'}'}
                            </pre></code>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <h3 >Node (Request)</h3>
                            <code>
                            <pre>
                                var request = require("request");<br/>
                                var options = {'{'}<br/>
                                &emsp;&emsp;method: 'POST',<br/>
                                &emsp;&emsp;url: '{LoginUrl}',<br/>
                                &emsp;&emsp;headers: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;'Content-Type': 'application/json'<br/>
                                &emsp;&emsp;{'}'},<br/>
                                &emsp;&emsp;body: {'{'} Email: '[Email]', Password: '[Password]' {'}'}<br/>
                                {'}'};<br/>
                                <br/>
                                request(options, function (error, response, body) {'{'}<br/>
                                &emsp;&emsp;if (error) throw new Error(error);<br/>
                                &emsp;&emsp;console.log(body);<br/>
                                {"}"});
                            </pre>
                            </code>
                        </div>
                    </div>
                </div>
            </PageBodyContainer>
        )
    }
}

export default Auth;
