import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Home from "./pages/Home";
import Header from "./shared/Header";
import Navigation from "./shared/Navigation";
import Footer from "./shared/Footer";
import Items from "./pages/Items";
import Orders from "./pages/Orders";
import Auth from "./pages/Auth";
import Shipping from "./pages/Shipping";
import SDK from "./pages/SDK";
import SecretOrders from "./pages/SecretOrders";
import SecretItems from "./pages/SecretItems";


class AppRouter extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const scrollTo = document.getElementById(window.location.href.split('#')[1]);
        let element = document.getElementById("root");
        if(scrollTo && element){
            element = element.parentElement;
            if(element){
                element = element.parentElement;
                if(element){
                    setTimeout(() => {
                        element.scrollTop = scrollTo.offsetTop;
                    }, 250);
                }
            }
        }
    }

    render() {
        return(
            <BrowserRouter>
                <div>
                    <Header/>
                    <Navigation/>
                    <Switch>
                        <Route path={'/sdk'} component={SDK}/>
                        <Route path={'/shipping'} component={Shipping}/>
                        <Route path={'/auth'} component={Auth}/>
                        <Route path={'/items'} component={Items}/>
                        <Route path={'/order'} component={Orders}/>
                        <Route path={"/secretitems"} component={SecretItems}/>
                        <Route path={"/secretorder"} component={SecretOrders}/>
                        <Route path={'/'} component={Home}/>
                    </Switch>
                    <Footer/>
                </div>
            </BrowserRouter>
        )
    }
}

export default AppRouter;
