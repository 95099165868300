import React, { Component } from 'react';
import Logo from "../content/images/lipseys-logo.svg";
import './shared.scss';
import {Link} from "react-router-dom";
class Header extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div id="header" className="p-2 text-center">
                <Link to={""}>
                    <img src={Logo} alt={"Logo"}/>
                </Link>
            </div>
        )
    }
}

export default Header;