import React, { Component } from 'react';
import PageBodyContainer from "../shared/components/PageBodyContainer";
import {Link} from "react-router-dom";
import {LipseysApiUrl} from "../shared/Constants";

const url = LipseysApiUrl + "Integration/Items";
class SecretItems extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <PageBodyContainer>
                <h1>Items</h1>
                <h3>api/Integration/Items</h3>
                <ul>
                    <li><a href="#Validate"><strong>Validate Item</strong></a></li>
                    <li><a href="#PricingQuantityFeed"><strong>Pricing Quantity Feed</strong></a></li>
                    <li><a href="#CatalogFeed"><strong>Catalog Feed</strong></a></li>
                    <li><a href="#CatalogFeedItem"><strong>Catalog Feed Item</strong></a></li>
                    <li><a href="#AllocationPricingQuantityFeed"><strong>Allocation Pricing Quantity Feed</strong></a></li>
                </ul>
                <hr/>

                {/* Validate */}
                <div className="row" id="Validate">
                    <div className="col-md-6">
                        <h3>Validate Item</h3>
                        <h4>POST - api/Integration/Items/ValidateItem</h4>
                        <p>POST - Validates item for ordering</p>
                        <p>USE CASE - The Lipsey's Catalog is consumed every few hours and the quantity of items changes regularly.
                            Using ValidateItem during a website's checkout process can provide a more accurate quantity and help prevent partially filled orders.</p>
                        <p>Session Token should be passed as a header as Token.<br/>Ex: Token: nMFW97zPsmmEU64VVSjg8aaO7PhkutYWqvOnAPC9PAE=</p>
                        <p>To obtain a session token see <Link to={"auth"}><strong>Authentication</strong></Link></p>
                        <div>
                            <strong>Body Parameters:</strong><br/>
                            <code><pre>
                                String <strong className="text-danger">(Item Number - Required)</strong><br/>
                                <strong className="text-danger">[Item Number can be Lipsey's Item #, MFG Model #, or UPC]</strong>
                            </pre></code>
                        </div>
                        <div>
                            <strong>Response example:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;"data": [<br/>
                                &emsp;&emsp;&emsp;&emsp;{'{'}<br />
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"qty": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"price": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"blocked": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"allocated": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"itemNumber": String<br/>
                                &emsp;&emsp;&emsp;&emsp;{'}'}<br />
                                &emsp;&emsp;]<br/>
                                {'}'}
                            </pre></code>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <h3>Node (Request)</h3>
                            <code><pre>
                                var request = require("request");<br/><br/>
                                var options = {'{'}<br/>
                                &emsp;&emsp;method: 'POST',<br/>
                                &emsp;&emsp;url: '{url}/ValidateItem',<br/>
                                &emsp;&emsp;headers: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Token: '<Link to={"auth"}>Token</Link>',<br/>
                                &emsp;&emsp;&emsp;&emsp;Content-Type: 'application/json'<br/>
                                &emsp;&emsp;{'}'},<br/>
                                &emsp;&emsp;body: [ItemNumber]<br/>
                                {'}'};<br/><br/>
                                request(options, function (error, response, body) {'{'}<br/>
                                &emsp;&emsp;if (error) throw new Error(error);<br/>
                                &emsp;&emsp;console.log(body);<br/>
                                {'}'});
                            </pre></code>
                        </div>
                    </div>
                </div>
                <hr/>

                {/* PricingQuantityFeed */}
                <div className="row" id="PricingQuantityFeed">
                    <div className="col-md-6">
                        <h3>Pricing And Quantity Feed</h3>
                        <h4>GET - api/Integration/Items/PricingQuantityFeed</h4>
                        <p>Pricing Quantity Feed - Get Catalog pricing and quantity based on logged in account. If account can order firearms, firearms will be included. If the account can order NFA, nfa will be included.</p>
                        <p>Pricing And Quantity Feed will not update more than <strong>once per hour</strong>, repeated requests will return cached results.</p>
                        <p>Session Token should be passed as a header as Token.<br/>Ex: Token: nMFW97zPsmmEU64VVSjg8aaO7PhkutYWqvOnAPC9PAE=</p>
                        <p>To obtain a session token see <Link to={"auth"}>Authentication</Link></p>
                        <div>
                            <strong>Body Parameters:</strong><br/>
                            <code className="fs-120"><pre>None</pre></code>
                        </div>
                        <div>
                            <strong>Response example:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;"data": {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;"nextUpdate": DateTime,<br/>
                                &emsp;&emsp;&emsp;&emsp;"items": [<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"itemNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"upc": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"mfgModelNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"quantity": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"allocated": bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"onSale": bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"price": decimal,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"currentPrice": decimal,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"retailMap": decimal<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;&emsp;&emsp;]<br/>
                                &emsp;&emsp;{'}'}<br/>
                                {'}'}
                            </pre></code>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <h3>Node (Request)</h3>
                            <code><pre>
                                var request = require("request");<br/>
                                var options = {'{'}<br/>
                                &emsp;&emsp;method: 'GET',<br/>
                                &emsp;&emsp;url: '{url}/PricingQuantityFeed',<br/>
                                &emsp;&emsp;headers: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Token: '<Link to={"auth"}>Token</Link>',<br/>
                                &emsp;&emsp;{'}'}<br/>
                                {'}'};<br/><br/>
                                request(options, function (error, response, body) {'{'}<br/>
                                &emsp;&emsp;if (error) throw new Error(error);<br/>
                                &emsp;&emsp;console.log(body);<br/>
                                {'}'});
                            </pre></code>
                        </div>
                    </div>
                </div>
                <hr/>


                {/* CatalogFeed */}
                <div className="row" id="CatalogFeed">
                    <div className="col-md-6">
                        <h3>Catalog Feed</h3>
                        <h4>GET - api/Integration/Items/CatalogFeed</h4>
                        <p>Catalog Feed - Get Catalog details based on logged in account. If account can order firearms, firearms will be included. If the account can order NFA, nfa will be included.</p>
                        <p>Catalog feed will not update more then <strong>once per four hours</strong>, repeated requests will return cached results.</p>
                        <p>
                            Images are available using the imageName fixed onto our image server url:  https://www.lipseyscloud.com/images/[image name]<br/>
                            <strong>Example: https://www.lipseyscloud.com/images/siw22045sp.jpg</strong><br/>
                            <strong className="text-danger">Please do not hotlink our images. Download them to your own hosting solution before use.</strong>
                        </p>
                        <p>Bound book information is not intended to use without checking the firearms you receive, Lipsey's is not responsible for the correctness of dealer bound books.</p>
                        <p>Session Token should be passed as a header as Token.<br/>Ex: Token: nMFW97zPsmmEU64VVSjg8aaO7PhkutYWqvOnAPC9PAE=</p>
                        <p>To obtain a session token see <Link to={"auth"}>Authentication</Link></p>
                        <div>
                            <strong>Body Parameters:</strong><br/>
                            <code className="fs-120"><pre>None</pre></code>
                        </div>
                        <div>
                            <strong>Response example:</strong><br/>
                            <strong>Response example:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;"data": [<br/>
                                &emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"itemNo": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"description1": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"description2": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"upc": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"manufacturerModelNo": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"msrp": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"model": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"caliberGauge": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"manufacturer": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"type": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"action": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"barrelLength": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"capacity": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"finish": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"overallLength": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"receiver": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"safety": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"sights": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"stockFrameGrips": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"magazine": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"weight": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"imageName": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"chamber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"drilledAndTapped": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"rateOfTwist": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"itemType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"additionalFeature1": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"additionalFeature2": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"additionalFeature3": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"shippingWeight": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"boundBookManufacturer": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"boundBookModel": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"boundBookType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"nfaThreadPattern": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"nfaAttachmentMethod": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"nfaBaffleType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"silencerCanBeDisassembled": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"silencerConstructionMaterial": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"nfaDbReduction": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"silencerOutsideDiameter": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"nfaForm3Caliber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"opticMagnification": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"maintubeSize": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"adjustableObjective": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"objectiveSize": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"opticAdjustments": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"illuminatedReticle": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"reticle": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"exclusive": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"quantity": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"allocated": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"onSale": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"price": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"currentPrice": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"retailMap": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"fflRequired": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"sotRequired": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"scopeCoverIncluded": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"special": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"sightsType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"case": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"choke": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"dbReduction": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"family": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"finishType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"frame": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"gripType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"handgunSlideMaterial": String<br/>
                                &emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;]<br/>
                                {'}'}
                            </pre></code>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <h3>Node (Request)</h3>
                            <code><pre>
                                var request = require("request");<br/>
                                var options = {'{'}<br/>
                                &emsp;&emsp;method: 'GET',<br/>
                                &emsp;&emsp;url: '{url}/CatalogFeed',<br/>
                                &emsp;&emsp;headers: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Token: '<Link to={"auth"}>Token</Link>',<br/>
                                &emsp;&emsp;{'}'}<br/>
                                {'}'};<br/><br/>
                                request(options, function (error, response, body) {'{'}<br/>
                                &emsp;&emsp;if (error) throw new Error(error);<br/>
                                &emsp;&emsp;console.log(body);<br/>
                                {'}'});
                            </pre></code>
                        </div>
                    </div>
                </div>
                <hr/>

                {/* CatalogFeed Item*/}
                <div className="row" id="CatalogFeedItem">
                    <div className="col-md-6">
                        <h3>Catalog Feed Item</h3>
                        <h4>POST - api/Integration/Items/CatalogFeed/Item</h4>
                        <p>Catalog Feed Item - Get Catalog details for one item based on logged in account. Item number parameter can be a Lipsey's Item Number, a UPC or a Manufacturer Model Number. If account can order firearms, firearms will be included. If the account can order NFA, nfa will be included.</p>
                        <p>
                            Images are available using the imageName fixed onto our image server url:  https://www.lipseyscloud.com/images/[image name]<br/>
                            <strong>Example: https://www.lipseyscloud.com/images/siw22045sp.jpg</strong>
                        </p>
                        <p>Bound book information is not intended to use without checking the firearms you receive, Lipsey's is not responsible for the correctness of dealer bound books.</p>
                        <p>Session Token should be passed as a header as Token.<br/>Ex: Token: nMFW97zPsmmEU64VVSjg8aaO7PhkutYWqvOnAPC9PAE=</p>
                        <p>To obtain a session token see <Link to={"auth"}>Authentication</Link></p>
                        <div>
                            <strong>Body Parameters:</strong><br/>
                            <code><pre>
                                String <strong className="text-danger">(Required, Item Number)</strong><br/>
                                <strong className="text-danger">[Item Number can be Lipsey's Item #, MFG Model #, or UPC]</strong>
                            </pre></code>
                        </div>
                        <div>
                            <strong>Response example:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;"data": {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;"itemNo": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"description1": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"description2": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"upc": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"manufacturerModelNo": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"msrp": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;"model": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"caliberGauge": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"manufacturer": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"type": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"action": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"barrelLength": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"capacity": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"finish": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"overallLength": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"receiver": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"safety": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"sights": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"stockFrameGrips": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"magazine": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"weight": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"imageName": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"chamber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"drilledAndTapped": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"rateOfTwist": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"itemType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"additionalFeature1": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"additionalFeature2": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"additionalFeature3": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"shippingWeight": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;"boundBookManufacturer": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"boundBookModel": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"boundBookType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"nfaThreadPattern": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"nfaAttachmentMethod": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"nfaBaffleType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"silencerCanBeDisassembled": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"silencerConstructionMaterial": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"nfaDbReduction": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"silencerOutsideDiameter": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"nfaForm3Caliber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"opticMagnification": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"maintubeSize": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"adjustableObjective": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"objectiveSize": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"opticAdjustments": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"illuminatedReticle": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"reticle": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"exclusive": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"quantity": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;"allocated": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"onSale": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"price": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;"currentPrice": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;"retailMap": Double,<br/>
                                &emsp;&emsp;&emsp;&emsp;"fflRequired": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"sotRequired": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"scopeCoverIncluded": Bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;"special": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"sightsType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"case": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"choke": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"dbReduction": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"family": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"finishType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"frame": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"gripType": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;"handgunSlideMaterial": String<br/>
                                &emsp;&emsp;{'}'}<br/>
                                {'}'}
                            </pre></code>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <h3>Node (Request)</h3>
                            <code><pre>
                                var request = require("request");<br/>
                                var options = {'{'}<br/>
                                &emsp;&emsp;method: 'POST',<br/>
                                &emsp;&emsp;url: '{url}/CatalogFeed/Item',<br/>
                                &emsp;&emsp;headers: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Token: '<Link to={"auth"}>Token</Link>',<br/>
                                &emsp;&emsp;&emsp;&emsp;'Content-Type': 'application/json'<br/>
                                &emsp;&emsp;{'}'},<br/>
                                &emsp;&emsp;body: '[Item #, UPC, MFG Model #]'
                                {'}'};<br/><br/>
                                request(options, function (error, response, body) {'{'}<br/>
                                &emsp;&emsp;if (error) throw new Error(error);<br/>
                                &emsp;&emsp;console.log(body);<br/>
                                {'}'});
                            </pre></code>
                        </div>
                    </div>
                </div>
                <hr/>

                {/* AllocationPricingQuantityFeed */}
                <div className="row" id="AllocationPricingQuantityFeed">
                    <div className="col-md-6">
                        <h3>Allocation Pricing And Quantity Feed</h3>
                        <h4>GET - api/Integration/Items/Allocations</h4>
                        <p>Allocation Pricing Quantity Feed - Get Catalog pricing and quantity of the logged in account's <strong>allocations</strong></p>
                        <p>Allocation Pricing And Quantity Feed will not update more than <strong>once per hour</strong>, repeated requests will return cached results.</p>
                        <p>Session Token should be passed as a header as Token.<br/>Ex: Token: nMFW97zPsmmEU64VVSjg8aaO7PhkutYWqvOnAPC9PAE=</p>
                        <p>To obtain a session token see <Link to={"auth"}>Authentication</Link></p>
                        <div>
                            <strong>Body Parameters:</strong><br/>
                            <code className="fs-120"><pre>None</pre></code>
                        </div>
                        <div>
                            <strong>Response example:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;"data": {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;"nextUpdate": DateTime,<br/>
                                &emsp;&emsp;&emsp;&emsp;"items": [<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"itemNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"upc": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"mfgModelNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"quantity": Int,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"allocated": bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"onSale": bool,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"price": decimal,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"currentPrice": decimal,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"retailMap": decimal<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;&emsp;&emsp;]<br/>
                                {'}'}
                            </pre></code>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <h3>Node (Request)</h3>
                            <code><pre>
                                var request = require("request");<br/>
                                var options = {'{'}<br/>
                                &emsp;&emsp;method: 'GET',<br/>
                                &emsp;&emsp;url: '{url}/Allocations',<br/>
                                &emsp;&emsp;headers: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Token: '<Link to={"auth"}>Token</Link>',<br/>
                                &emsp;&emsp;{'}'}<br/>
                                {'}'};<br/><br/>
                                request(options, function (error, response, body) {'{'}<br/>
                                &emsp;&emsp;if (error) throw new Error(error);<br/>
                                &emsp;&emsp;console.log(body);<br/>
                                {'}'});
                            </pre></code>
                        </div>
                    </div>
                </div>
                <hr/>

            </PageBodyContainer>
        )
    }
}

export default SecretItems;
