import React, { Component } from 'react';


class Footer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div className="container-fluid text-center noPrint">
                <div className="d-inline-block">
                    Copyright © {new Date().getFullYear()} Lipsey's LLC All rights reserved.</div>
            </div>
        )
    }
}

export default Footer;