import React, { Component } from 'react';
import PageBodyContainer from "../shared/components/PageBodyContainer";
import {Link} from "react-router-dom";

const emailSubject = 'API Access Request';
class Home extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <PageBodyContainer>
                <h1>Lipsey's API</h1>
                <h3>Preferred Partners</h3>
                <p>
                    Lipsey's offers Software as a Service integrations through our{" "}
                    <a className="btn btn-primary" target="_blank" rel="noreferrer noopener" href="https://www.lipseys.com/partners">
                        Preferred Partners
                    </a>
                </p>
                <p>
                    Integrating through one of our partners is ideal for dealers looking for pre-built software with dedicated support. {" "}
                    Integrations with our partners are automatically approved {" "}
                    and <strong>do not require filling out the form.</strong>

                </p>
                <h3>Getting Started</h3>
                <p>
                    To access Lipsey's API you first need to have your <strong>Domains And IP Addresses Pre-Approved</strong> requests can be made.<br/>
                    Please{" "}
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://forms.office.com/Pages/ResponsePage.aspx?id=X654_c0rsk2wjv95IGAUMcxFCPJ9ZUFCqCosllGjJbtUOUFMSDhVVE1FSEYzV0w1WUY5S1BITFJWQSQlQCN0PWcu"
                    >
                        <button className="btn btn-primary">Fill Out This Form</button>
                    </a>{" "}
                    to request access.
                </p>
                <p>
                    Most requests require authorization, and requests that do not may return information unique to a dealer when authorized.<br/>
                    To make an authorized request, a login request must be made and the token returned with the login response
                    will be used for all requests to be authorized.
                </p>
                <p>See <Link to={"auth"}><button className="btn btn-primary">Authorization</button></Link> for details on login and obtaining an authorization token.</p>
                <h3>Request And Response Format</h3>
                <p>Most Requests made to Lipsey's API is made through POST HTTP Requests. Every request made returns a base data set that includes a data field.</p>


                <div className="row">
                    <div className="col-md-6">
                        <p><strong>Success </strong>represents if the request was successful.</p>
                        <p>
                            <strong>Authorized </strong>
                            represents that the request made had an authorization token, and that that token was valid.<br/>
                            Depending on the request, a request is not required to be authorized in order to be successful.
                        </p>
                        <p><strong>Errors </strong>is an array of strings containing the error messages if a request was either partially or completely unsuccessful.</p>
                        <p><strong>Data </strong>is the object that will contain the information requested.</p>
                    </div>
                    <div className="fs-150 align-self-center p-4">
                        <code>
                            <pre>
                               {'{'}<br/>
                                &emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;"data": Object<br/>
                                {'}'}
                            </pre>
                        </code>
                    </div>
                </div>

                <h3>Item Numbers</h3>
                <p><strong>Any Item Number Input Fields can be a <span className="text-danger">Lipsey's Item Number, a Manufacturer Model Number, or a UPC</span></strong></p>
                <p><strong>Example</strong>: For <a href={"https://www.lipseys.com/itemdetail?itemno=RULCP"} target="_blank" rel="noreferrer noopener"><strong>THIS</strong></a> item we would accept "RULCP", "3701", or "736676037018"</p>



                <h3>Prebuilt Integrations</h3>
                <p>Working in C#, VB, NodeJs, or in PHP? We have packages available for easy integration!</p>
                <p><Link to={"/sdk"}><button className="btn btn-primary">Prebuilt Integrations</button></Link></p>

                <h3>Postman Import</h3>
                <div className="row mb-4">
                    <div className="col-12 d-inline-block h-100 mb-4">
                        <p>Postman is a tool to test APIs, share request examples, and generate code for requests. </p>
                        <p>
                            To test the API endpoints in Postman, first, download and install the Postman desktop application using the link below. 
                            After Postman is installed, click the 'Run in Postman' button and import a copy of the collection.    
                        </p>
                        <p>
                            <a 
                                className="font-weight-bold fs-120"
                                href="https://www.postman.com/downloads/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                    Download Postman
                            </a>
                        </p>
                        <p>
                            <div
                                className="postman-run-button"
                                data-postman-action="collection/fork"
                                data-postman-var-1="24886281-34a870a1-cee1-4522-b149-4b8e7ae49edd"
                                data-postman-collection-url="entityId=24886281-34a870a1-cee1-4522-b149-4b8e7ae49edd&entityType=collection&workspaceId=70212ef7-514c-4fe8-a279-17c0028339fe" 
                            />
                        </p>
                        <script type="text/javascript">
                            {
                                (function (p,o,s,t,m,a,n) {
                                    !p[s] && (p[s] = function () { (p[t] || (p[t] = [])).push(arguments); });
                                    !o.getElementById(s+t) && o.getElementsByTagName("head")[0].appendChild((
                                        (n = o.createElement("script")),
                                        (n.id = s+t), (n.async = 1), (n.src = m), n
                                        ));
                                    }(window, document, "_pm", "PostmanRunObject", "https://run.pstmn.io/button.js"))
                                }
                        </script>
                        
                        <p>
                            The Postman collection must be run using the <strong>Postman desktop application on a computer with a whitelisted IP address</strong>.
                            Testing the API endpoints using the Postman website or a computer without a whitelisted IP address will always generate a 'Not Authorized' response. 
                        </p>
                    </div>
                    <div className="col-12 d-inline-block">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/dvIib7ZA98I" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                </div>

                <div>

                </div>



                <h3>Rate Limitations</h3>
                <p>API is rate limited, each endpoint may have a unique rate limit,
                    which is described in these docs on each request.</p>
                <p>Abuse of the API may result in revoked access which can be appealed by <a href="https://lipseys.com/contactus.aspx" target="_blank" rel="noreferrer noopener">contacting us</a>.</p>
            </PageBodyContainer>
        )
    }
}

export default Home;
