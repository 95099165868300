import React, { Component } from 'react';

class PageBodyContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div className="container PageBodyContainer p-2" style={{minHeight: "calc(100vh - 200px)"}}>{this.props.children}</div>
        )
    }
}

export default PageBodyContainer;