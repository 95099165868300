import React, { Component } from 'react';
import PageBodyContainer from "../shared/components/PageBodyContainer";
import {Link} from "react-router-dom";

const emailSubject = 'API Access Request';

class SDK extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <PageBodyContainer>
                <h1>Lipsey's API Prebuilt Integrations</h1>
                <div className="row">
                    <div className="col-12">
                        <p>Don't see a language you need? <a href="mailto:api.lipseys.com?subject=New Lipseys API Package Request" target="_blank" rel="noreferrer noopener"><strong>Let us know!</strong></a></p>



                        <p><strong className="text-danger">See specific packages' documentation for usage examples.</strong></p>
                    </div>
                    <div className="col-12">
                        <h2>.NET (C# & VB)</h2>
                        <ul>
                            <li><strong><a href="https://www.nuget.org/packages/LipseysApiInterface452/" target="_blank" rel="noreferrer noopener">.Net 4.5.2</a></strong></li>
                            <li><strong><a href="https://www.nuget.org/packages/LipseysApiInterface/" target="_blank" rel="noreferrer noopener">.Net 4.6 through .Net Core 3</a></strong></li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <h2>NodeJs</h2>
                        <ul>
                            <li><strong><a href="https://www.npmjs.com/package/lipseys-api" target="_blank" rel="noreferrer noopener">NodeJs</a></strong></li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <h2>PHP</h2>
                        <ul>
                            <li><strong><a href="https://packagist.org/packages/lipseys/apiintegration" target="_blank" rel="noreferrer noopener">PHP</a></strong></li>
                        </ul>
                    </div>
                </div>
            </PageBodyContainer>
        )
    }
}

export default SDK;