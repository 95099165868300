import React, { Component } from 'react';
import {Link} from "react-router-dom";


class Navigation extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div id="Navigation">
                <div className="container p-0">
                    <ul className="nav navbar-nav py-2 d-flex flex-row">
                        <li className="p-2"><Link to={"/"}>Home</Link></li>
                        <li className="p-2"><Link to={"/auth"}>Authentication</Link></li>
                        <li className="p-2"><Link to={"/items"}>Items</Link></li>
                        <li className="p-2"><Link to={"/order"}>Order</Link></li>
                        <li className="p-2"><Link to={"/shipping"}>Shipping</Link></li>
                        <li className="p-2"><Link to={"/sdk"}>Prebuilt Integrations</Link></li>
                    </ul>
                </div>

            </div>
        )
    }
}

export default Navigation;
