import React, { Component } from 'react';
import PageBodyContainer from "../shared/components/PageBodyContainer";
import {Link} from "react-router-dom";
import {LipseysApiUrl} from "../shared/Constants";

const url = LipseysApiUrl + "Integration/Shipping";
class Shipping extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <PageBodyContainer>
                <h1>Shipping</h1>
                <h3>api/Integration/Shipping</h3>
                <ul>
                    <li><a href="#OneDay"><strong>OneDay</strong></a></li>
                </ul>
                <hr/>

                {/* OneDay */}
                <div className="row" id="OneDay">
                    <div className="col-md-6">
                        <h3>OneDay</h3>
                        <h4>POST - api/Integration/Shipping/OneDay</h4>
                        <p>Searches Shipped Orders/Invoices by date and returns the shipping information for each Order/Invoice found.</p>
                        <p>
                            This endpoint should be used once a day, and target the previous day's invoices.
                            <br/>This endpoint returns data for entire account.
                            <br/>EX: Today is 2/4/2020, the date provided should be 2/3/2020 or earlier.
                        </p>
                        <p>Session Token should be passed as a header as Token.<br/>Ex: Token: nMFW97zPsmmEU64VVSjg8aaO7PhkutYWqvOnAPC9PAE=</p>
                        <p>To obtain a session token see <Link to={"auth"}>Authentication</Link></p>
                        <div>
                            <strong>Body Parameters:</strong><br/>
                            <code><pre>
                                Date <strong className="text-danger">(Required - Ex: "7/20/2019")</strong>
                            </pre></code>
                            <br/>
                        </div>
                        <div>
                            <strong>Response example:</strong><br/>
                            <code><pre>
                                {'{'}<br/>
                                &emsp;&emsp;"success": Bool,<br/>
                                &emsp;&emsp;"authorized": Bool,<br/>
                                &emsp;&emsp;"errors": [String],<br/>
                                &emsp;&emsp;"data": [<br/>
                                &emsp;&emsp;&emsp;&emsp;{'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"invoiceNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"orderNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"poNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"billName": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"shipName": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"shippingService": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"trackingNumber": String,<br/>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"weight": String<br/>
                                &emsp;&emsp;&emsp;&emsp;{'}'}<br/>
                                &emsp;&emsp;]<br/>
                                {'}'}
                            </pre></code>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <h3>Node (Request)</h3>
                            <code><pre>
                                var request = require("request");<br/><br/>
                                var options = {'{'}<br/>
                                &emsp;&emsp;method: 'POST',<br/>
                                &emsp;&emsp;url: '{url}/OneDay',<br/>
                                &emsp;&emsp;headers: {'{'}<br/>
                                &emsp;&emsp;&emsp;&emsp;Token: '<Link to={"auth"}>Token</Link>',<br/>
                                &emsp;&emsp;{'}'},<br/>
                                &emsp;&emsp;body: [Date]
                                {'}'};<br/><br/>
                                request(options, function (error, response, body) {'{'}<br/>
                                &emsp;&emsp;if (error) throw new Error(error);<br/>
                                &emsp;&emsp;console.log(body);<br/>
                                {'}'});
                            </pre></code>
                        </div>
                    </div>
                </div>
            </PageBodyContainer>
        )
    }
}

export default Shipping;
